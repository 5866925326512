import root from "window-or-global";
import {isDevice} from "@atg/utils/device";
import {trackEvent, type TrackEvent} from "@atg-shared/analytics";
import type {CasinoTrackingEvent} from "@atg-casino-shared/types-analytics";
import {noTrans} from "@atg-shared/no-trans";
import type {GAME_TYPE} from "@atg-casino-shared/utils-constants";

type GameInfo = {
    vendor: string;
    vendorGameId: string;
    id: string;
};

// re-assigning the general trackEvent function to get type checking for CasinoTrackingEvent
const trackCasinoEvent: TrackEvent<CasinoTrackingEvent> = trackEvent;

function deviceOrientation() {
    if (!isDevice() || typeof root?.matchMedia !== "function") return undefined;
    return root.matchMedia("(orientation: landscape)").matches ? "landscape" : "portrait";
}

export const gameLaunch = (
    game: GameInfo | null | undefined,
    moneyType: GAME_TYPE,
    element?: string,
) => {
    if (!game) return;
    const {vendor, vendorGameId, id: gameId} = game;
    trackCasinoEvent({
        moneyType,
        vendor,
        gameId,
        vendorGameId,
        event: "casinoGameLaunchPress",
        element,
        deviceOrientation: deviceOrientation(),
    });
};

export const gameStart = (
    game: GameInfo | null | undefined,
    moneyType: GAME_TYPE,
    loadTime?: number,
) => {
    if (!game) return;
    const {vendor, vendorGameId, id: gameId} = game;
    trackCasinoEvent({
        moneyType,
        vendor,
        gameId,
        vendorGameId,
        event: "casinoGameStart",
        loadTime,
        deviceOrientation: deviceOrientation(),
    });
};

export const gameStartError = (
    game: GameInfo | null | undefined,
    moneyType: GAME_TYPE,
    reason: string,
) => {
    if (!game) return;
    const {vendor, vendorGameId, id: gameId} = game;
    trackCasinoEvent({
        moneyType,
        vendor,
        gameId,
        vendorGameId,
        event: "casinoGameStartError",
        reason,
        deviceOrientation: deviceOrientation(),
    });
};

export const gameClose = (game: GameInfo | null | undefined, moneyType: GAME_TYPE) => {
    if (!game) return;
    const {vendor, vendorGameId, id: gameId} = game;
    trackCasinoEvent({
        moneyType,
        vendor,
        gameId,
        vendorGameId,
        event: "casinoGameClose",
        deviceOrientation: deviceOrientation(),
    });
};

export const showLossLimit = () => {
    trackCasinoEvent({
        event: "casinoShowMandatoryLossLimit",
        deviceOrientation: deviceOrientation(),
    });
};

export const setLossLimit = (limit: number) => {
    trackCasinoEvent({
        limit,
        event: "casinoSetMandatoryLossLimit",
        deviceOrientation: deviceOrientation(),
    });
};

export const selfExclusionConfirm = (
    game: GameInfo | null | undefined,
    moneyType: GAME_TYPE,
) => {
    if (!game) return;
    const {vendor, vendorGameId, id: gameId} = game;
    trackCasinoEvent({
        moneyType,
        vendor,
        gameId,
        vendorGameId,
        event: "casinoSelfExclusionConfirm",
        deviceOrientation: deviceOrientation(),
    });
};

export const selfExclusionDismiss = (
    game: GameInfo | null | undefined,
    moneyType: GAME_TYPE,
) => {
    if (!game) return;
    const {vendor, vendorGameId, id: gameId} = game;
    trackCasinoEvent({
        moneyType,
        vendor,
        gameId,
        vendorGameId,
        event: "casinoSelfExclusionDismiss",
        deviceOrientation: deviceOrientation(),
    });
};

export const gameRoundStart = (
    game: GameInfo | null | undefined,
    moneyType: GAME_TYPE | null | undefined,
    numOfSpins: number,
) =>
    game &&
    moneyType &&
    (numOfSpins === 1 || numOfSpins % 10 === 0) &&
    trackCasinoEvent({
        moneyType,
        vendor: game.vendor,
        gameId: game.id,
        vendorGameId: game.vendorGameId,
        event: "casinoGameRoundStart",
        numOfSpins,
        deviceOrientation: deviceOrientation(),
    });

export const relatedGameClick = (
    gameId: string,
    relatedType: string | undefined,
    parentGameId: string | undefined,
    gameIndex: number | undefined,
) => {
    trackCasinoEvent({
        event: "casinoProductPageRelatedGameClick",
        gameId,
        gameIndex,
        relatedType,
        parentGameId,
        deviceOrientation: deviceOrientation(),
    });
};

export const browseGameClick = (fromGameId: string, toGameId: string) =>
    trackCasinoEvent({
        event: "casinoProductPageBrowseGameClick",
        fromGameId,
        toGameId,
        deviceOrientation: deviceOrientation(),
    });

export const tagClick = (gameId: string | undefined, tagId: string | undefined) => {
    trackCasinoEvent({
        event: "casinoProductPageTagClick",
        gameId,
        tagId,
        deviceOrientation: deviceOrientation(),
    });
};

export const quicklinksSearchClick = () => {
    trackCasinoEvent({
        event: "casinoQuickLinkSearch",
        deviceOrientation: deviceOrientation(),
    });
};

export const articlesStartpageClick = (title: string) => {
    trackCasinoEvent({
        event: "casinoArticlesStartpageClick",
        title,
        deviceOrientation: deviceOrientation(),
    });
};

export const randomGameDisplayMount = () => {
    trackCasinoEvent({
        event: "casinoRandomGameMount",
        deviceOrientation: deviceOrientation(),
    });
};

export const randomGameDisplayExpand = () => {
    trackCasinoEvent({
        event: "casinoRandomGameExpand",
        deviceOrientation: deviceOrientation(),
    });
};

export const randomGameDisplaySpin = (gameId = "", firstSpin: boolean) => {
    trackCasinoEvent({
        event: "casinoRandomGameSpin",
        spunOn: firstSpin ? "firstSpin" : gameId,
        deviceOrientation: deviceOrientation(),
    });
};

export const randomGameDisplayPlay = (gameId: string, source: string) => {
    trackCasinoEvent({
        event: "casinoRandomGamePlay",
        gameId,
        source,
        deviceOrientation: deviceOrientation(),
    });
};

export const singleGameDisplayClick = (gameId: string, source: string) => {
    trackCasinoEvent({
        event: "casinoSingleGameDisplayClick",
        gameId,
        source,
        deviceOrientation: deviceOrientation(),
    });
};

export const collectionGameClick = (
    collectionId: string,
    gameId: string,
    slot: number,
) => {
    trackCasinoEvent({
        event: "casinoCollectionGameClick",
        collectionId,
        gameId,
        slot,
        deviceOrientation: deviceOrientation(),
    });
};

export const collectionMultiGameClick = (collectionId: string, slot: number) => {
    trackCasinoEvent({
        event: "casinoCollectionMultiGameClick",
        collectionId,
        gameId: "multigame",
        slot,
        deviceOrientation: deviceOrientation(),
    });
};

export const playlistsStartpageClick = (slot: number) => {
    trackCasinoEvent({
        event: "casinoPlaylistsStartpageClick",
        slot,
        deviceOrientation: deviceOrientation(),
    });
};

export const recentlyPlayedStartpageClick = (slot: string | number) => {
    trackCasinoEvent({
        event: "casinoRecentlyPlayedStartpageClick",
        slot,
        deviceOrientation: deviceOrientation(),
    });
};

export const submitSearchEvent = (modalId: string, term: string, itemValue: string) => {
    trackCasinoEvent({
        event: "casinoSubmitSearch",
        modalId,
        term,
        itemValue,
        itemKey: "",
        deviceOrientation: deviceOrientation(),
    });
};

export const abortSearchEvent = (modalId: string, term: string, press: string) => {
    trackCasinoEvent({
        event: "casinoAbortSearch",
        modalId,
        term,
        press,
        deviceOrientation: deviceOrientation(),
    });
};

export const userAboveDepositLimitCap = () =>
    trackCasinoEvent({event: "casinoUserAboveDepositLimitCap"});

export const userBelowDepositLimitCap = () =>
    trackCasinoEvent({event: "casinoUserBelowDepositLimitCap"});

export const userHasBeenAboveDepositLimitCap = () =>
    trackCasinoEvent({event: "casinoUserHasBeenAboveDepositLimitCap"});

export const winFeedClick = (gameId: string, winAmount: number | string) =>
    trackCasinoEvent({
        event: "casinoWinFeedClick",
        gameId,
        winAmount,
    });

export const atgCasinoJackpotEvent = (optedIn: boolean, isApp: boolean) =>
    trackCasinoEvent({
        event: "atgCasinoJackpot",
        attending: optedIn,
        platform: isApp ? "app" : "web",
    });

export const atgCasinoJackpotOptinEvent = (optedIn: boolean, isApp: boolean) =>
    trackCasinoEvent({
        event: "toggleAtgCasinoJackpot",
        choice: optedIn ? noTrans("toggle on") : noTrans("toggle off"),
        platform: isApp ? "app" : "web",
    });
