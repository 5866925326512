import {FETCH, call} from "@atg-shared/fetch-redux";
import {fetchAuthorized} from "@atg-shared/auth";
import type {FavoriteGame} from "@atg-casino-shared/types-favorites";
import type {
    ToggleFavoriteGameAction,
    FavoriteGamesFetchAction,
    ClearFavoriteGamesAction,
    GameAddedFetchAction,
    GameDeletedFetchAction,
    ToggleLimitModalAction,
} from "./favorites.types";
import * as constants from "./favorites.constants";

export const fetchFavoritesGames = (): FavoriteGamesFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: constants.REQUEST_FAVORITES,
        receiveAction: constants.RECEIVE_FAVORITES,
        callApi: call(
            fetchAuthorized,
            `${constants.FAVORITES_ENDPONT}?context=${constants.FAVORITES_CONTEXT}`,
            {method: "GET"},
        ),
    },
});

export const toggleFavoriteGame = (gameId: string): ToggleFavoriteGameAction => ({
    type: constants.TOGGLE_FAVORITE_GAME,
    payload: {gameId},
});

export const gameAddedFetchAction = (game: FavoriteGame): GameAddedFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: constants.REQUEST_GAME_ADDED,
        receiveAction: constants.RECEIVE_GAME_ADDED,
        context: game,
        callApi: call(fetchAuthorized, constants.FAVORITES_ENDPONT, {
            method: "POST",
            body: JSON.stringify({
                context: constants.FAVORITES_CONTEXT,
                data: JSON.stringify(game),
            }),
        }),
    },
});

export const gameDeletedFetchAction = (game: FavoriteGame): GameDeletedFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: constants.REQUEST_DELETE_GAME,
        receiveAction: constants.RECEIVE_DELETE_GAME,
        context: game,
        callApi: call(fetchAuthorized, `${constants.FAVORITES_ENDPONT}/${game.id}`, {
            method: "DELETE",
        }),
    },
});

export const clearFavoriteGames = (): ClearFavoriteGamesAction => ({
    type: constants.CLEAR_FAVORITE_GAMES,
});

export const toggleLimitModal = (visible: boolean): ToggleLimitModalAction => ({
    type: constants.TOGGLE_LIMIT_MODAL,
    payload: {visible},
});
