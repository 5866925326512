import type {AtgJackpotState} from "@atg-casino-shared/types-atg-jackpot";
import * as constants from "../constants";
import type {JackpotErrorType, AtgJackpotActions} from "../types";
import {jackpotsArrayToObject} from "../helpers";

export const initialState: AtgJackpotState = {
    jackpots: {},
    optedIn: false,
    loginLoading: false,
    optInLoading: false,
};

export const atgJackpotReducer = (state = initialState, action: AtgJackpotActions) => {
    const {type} = action;

    switch (type) {
        case constants.UPDATE_ATG_JACKPOT: {
            const {payload} = action;

            return {
                ...state,
                jackpots: {
                    ...state.jackpots,
                    [payload.id]: payload,
                },
            };
        }

        case constants.REMOVE_ATG_JACKPOT: {
            const {payload} = action;

            const {[payload.jackpotId]: _, ...jackpots} = state.jackpots;

            return {
                ...state,
                jackpots,
            };
        }

        case constants.REQUEST_OPT_IN:
            return {...state, optInLoading: true, optInActionError: false};

        case constants.RECEIVE_OPT_IN: {
            const {payload, error} = action;

            if (!error && payload) {
                return {
                    ...state,
                    optedIn: payload.optedIn,
                    optInLoading: false,
                    optInActionError: false,
                };
            }

            return {...state, optInLoading: false, optInActionError: true};
        }

        case constants.REQUEST_ATG_JACKPOT_LOG_IN:
            return {...state, loginLoading: true, loginActionError: false};

        case constants.RECEIVE_ATG_JACKPOT_LOG_IN: {
            const {payload, error} = action;

            if (!error && payload) {
                return {
                    ...state,
                    optedIn: payload.optedIn,
                    loginLoading: false,
                    loginActionError: false,
                    jackpotPlayerId: payload.jackpotPlayerId,
                    jackpots: {
                        ...state.jackpots,
                        ...jackpotsArrayToObject(payload.jackpots),
                    },
                };
            }

            return {...state, loginLoading: false, loginActionError: true};
        }

        case constants.WIN_EVENT_CURRENT_USER:
        case constants.WIN_EVENT_OTHER_USER: {
            const {payload} = action;

            return {
                ...state,
                ...(action.type === constants.WIN_EVENT_CURRENT_USER
                    ? {currentUserJackpot: payload}
                    : {otherUserJackpot: payload}),
            };
        }

        case constants.CLEAR_WIN_EVENT: {
            const {
                payload: {currentUser},
            } = action;

            return {
                ...state,
                ...(currentUser
                    ? {currentUserJackpot: undefined}
                    : {otherUserJackpot: undefined}),
            };
        }

        case constants.CLEAR_JACKPOT_ERROR: {
            const {
                payload: {type: errorType},
            } = action;

            const errorMap: Record<JackpotErrorType, string> = {
                LOGIN: "loginActionError",
                OPT_IN: "optInActionError",
            };

            if (errorMap[errorType]) {
                return {
                    ...state,
                    [errorMap[errorType]]: undefined,
                };
            }

            return state;
        }

        case constants.RECEIVE_FETCH_ATG_JACKPOTS: {
            const {payload, error} = action;

            if (!error && payload) {
                return {
                    ...state,
                    jackpots: jackpotsArrayToObject(payload.jackpots),
                };
            }

            return state;
        }

        default:
            return state;
    }
};
