import {combineReducers} from "redux";
import type {FetchReducer, FetchState} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";

import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import type {
    RgsVerticalLimit,
    MultiPeriodicLimit,
} from "@atg-responsible-gambling-shared/limits-types";
import {
    RgsLimitTypeEnum,
    VerticalEnum,
} from "@atg-responsible-gambling-shared/limits-types";
import type {
    Action,
    FetchLoginTimeLimitsAction,
    FetchDepositLimitAction,
    DeleteRgsLimitsAction,
    FetchRgsLimitsAction,
} from "./limitsActions";
import {
    REQUEST_GET_RGS_LIMITS,
    REQUEST_DELETE_RGS_LIMITS,
    RESET_RGS_LIMITS_STATUS,
    RESET_STATUS,
    REQUEST_GET_DEPOSIT_LIMITS,
    RECEIVE_GET_DEPOSIT_LIMITS,
    RECEIVE_GET_RGS_LIMITS,
    RECEIVE_DELETE_RGS_LIMITS,
    RECEIVE_PUT_LOGIN_TIME_LIMITS,
    REQUEST_GET_LOGIN_TIME_LIMITS,
    RECEIVE_GET_LOGIN_TIME_LIMITS,
} from "./limitsActions";

/**
 * RgsVerticalReducer Factory
 */

const createRgsVerticalReducer = (
    vertical: VerticalEnum,
    limitType: Exclude<RgsLimitTypeEnum, RgsLimitTypeEnum.LOGIN>,
) => {
    const rgsVerticalReducer = (
        state: RgsVerticalLimit,
        action: DeleteRgsLimitsAction | FetchRgsLimitsAction,
    ) => {
        if (
            action.type === RECEIVE_DELETE_RGS_LIMITS ||
            action.type === RECEIVE_GET_RGS_LIMITS
        ) {
            if (action.error) return state;
            if (!action.context) return state;

            if (
                (!action.context.limitType || action.context.limitType === limitType) &&
                (!action.context.vertical || action.context.vertical === vertical)
            ) {
                const receivedVerticalLimits = action.payload?.[limitType]?.[vertical];

                if (receivedVerticalLimits) {
                    return {
                        ...state,
                        ...receivedVerticalLimits,
                        set: Boolean(action.context.set),
                    };
                }
            }
        }
        return state;
    };

    const deleteRgsLimitFetchReducer = createFetchReducer(
        REQUEST_DELETE_RGS_LIMITS,
        RECEIVE_DELETE_RGS_LIMITS,
        RESET_RGS_LIMITS_STATUS,
        rgsVerticalReducer,
        {},
    );

    const getRgsLimitFetchReducer = createFetchReducer(
        REQUEST_GET_RGS_LIMITS,
        RECEIVE_GET_RGS_LIMITS,
        RESET_RGS_LIMITS_STATUS,
        rgsVerticalReducer,
        {},
    );

    /**
     * RgsVerticalReducer function
     */

    /**
     * As there are 2 different fetch calls GET_RGS_LIMITS and DELETE_RGS_LIMITS which can change the state of a specific rgs limit.
     * so to get the loading state while getting and deleting specific rgs limit we are using 2 fetch reducer and chaining it in this reducer.
     *
     * Note: The loading state can be moved into separate reducer to keep this state simple.
     */

    const defaultState: FetchState<RgsVerticalLimit> = {
        __loadingState: {error: false, loaded: false, loading: false},
    };

    return (state = defaultState, action: Action) => {
        switch (action.type) {
            case REQUEST_GET_RGS_LIMITS:
            case RECEIVE_GET_RGS_LIMITS:
            case RESET_RGS_LIMITS_STATUS:
                return getRgsLimitFetchReducer(state, action);
            case REQUEST_DELETE_RGS_LIMITS:
            case RECEIVE_DELETE_RGS_LIMITS:
                return deleteRgsLimitFetchReducer(state, action);
            case LOGOUT_FINISHED:
                return defaultState;
            default:
                return state;
        }
    };
};

/**
 * RgsLimitsReducer factory
 */
const createRgsLimitsReducer = (
    rgsLimitType: Exclude<RgsLimitTypeEnum, RgsLimitTypeEnum.LOGIN>,
) =>
    combineReducers({
        [VerticalEnum.HORSE_BETTING]: createRgsVerticalReducer(
            VerticalEnum.HORSE_BETTING,
            rgsLimitType,
        ),
        [VerticalEnum.CASINO]: createRgsVerticalReducer(
            VerticalEnum.CASINO,
            rgsLimitType,
        ),
        [VerticalEnum.SPORTSBOOK]: createRgsVerticalReducer(
            VerticalEnum.SPORTSBOOK,
            rgsLimitType,
        ),
    });

export const initialLoginTimeState: FetchState<RgsVerticalLimit> = {
    __loadingState: {error: false, loaded: false, loading: false},
};

const loginTime: FetchReducer<RgsVerticalLimit, FetchLoginTimeLimitsAction> =
    createFetchReducer(
        REQUEST_GET_LOGIN_TIME_LIMITS,
        RECEIVE_GET_LOGIN_TIME_LIMITS,
        RESET_STATUS,
        (state: FetchState<RgsVerticalLimit>, action: Action) => {
            switch (action.type) {
                // Gets the limits from BE when loging in and visiting atg.se/konto/granser (/rg/limits when rgMicrofE is live)
                case RECEIVE_GET_LOGIN_TIME_LIMITS:
                    if (action.error) return state;

                    if (action.payload.loginTimeLimits) {
                        return {
                            day: action.payload.loginTimeLimits.day,
                            week: action.payload.loginTimeLimits.week,
                            month: action.payload.loginTimeLimits.month,
                        };
                    }

                    return state;

                // Gets the new added limits from BE after an update
                case RECEIVE_PUT_LOGIN_TIME_LIMITS:
                    if (action.error) return state;

                    if (action.payload) {
                        return {
                            day: action.payload.day,
                            week: action.payload.week,
                            month: action.payload.month,
                        };
                    }

                    return state;

                case LOGOUT_FINISHED:
                    return initialLoginTimeState;

                default:
                    return state;
            }
        },
        initialLoginTimeState,
    );

const rgsLimits = combineReducers({
    [RgsLimitTypeEnum.LOSS]: createRgsLimitsReducer(RgsLimitTypeEnum.LOSS),
    [RgsLimitTypeEnum.TIME]: createRgsLimitsReducer(RgsLimitTypeEnum.TIME),
    [RgsLimitTypeEnum.LOGIN]: loginTime,
});

export const INITIAL_DEPOSIT_STATE: MultiPeriodicLimit = {};

const deposit: FetchReducer<MultiPeriodicLimit, FetchDepositLimitAction> =
    createFetchReducer(
        REQUEST_GET_DEPOSIT_LIMITS,
        RECEIVE_GET_DEPOSIT_LIMITS,
        RESET_STATUS,
        (state: FetchState<MultiPeriodicLimit>, action: Action) => {
            if (action.type === RECEIVE_GET_DEPOSIT_LIMITS) {
                if (action.error) return state;
                return action.payload;
            }

            return state;
        },
        INITIAL_DEPOSIT_STATE,
    );

const limits = combineReducers({
    rgsLimits,
    deposit,
});

export default limits;
