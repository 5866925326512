import type {SagaIterator} from "redux-saga";
import {eventChannel} from "redux-saga";
import {
    put,
    race,
    take,
    takeLatest,
    call,
    cancel,
    delay,
    fork,
    spawn,
    join,
} from "redux-saga/effects";
import root from "window-or-global";
import * as Storage from "@atg-shared/storage";
import {
    fetchAuthorized,
    AuthenticationCancelledError,
    AuthActions,
} from "@atg-shared/auth";
import {AtgRequestError} from "@atg-shared/fetch-types";
import * as CasinoAnalytics from "@atg-casino-shared/utils-analytics";
import {
    getVendorConfig,
    getLaunchUrlV2,
    VendorsMigratedToV2,
} from "@atg-casino-shared/vendor";
import {
    GAME_TYPE,
    ERROR_RESPONSES,
    RECENT_GAMES,
} from "@atg-casino-shared/utils-constants";
import {VENDOR} from "@atg-casino-shared/types-vendor";
import type {Game} from "@atg-casino-shared/types-game";
import log from "@atg-shared/log";
import * as ModalActions from "atg-modals/modalActions";
import {VerticalEnum} from "@atg-responsible-gambling-shared/limits-types";
import {showKycModalBeforeBet} from "@atg-aml-shared/kyc-domain/src/saga/kycQuestionnaireSaga";
import {LimitsActions} from "@atg-responsible-gambling-shared/limits-domain";
import {pureFetch} from "@atg-shared/fetch";
import {MODAL_CLOSE} from "atg-modals/modalActionTypes";
import {frameAction} from "atg-store-addons";
import type {StartRemoteGameFlowAction, StartGameFlowAction} from "./game.flow.actions";
import {
    OPEN_REMOTE_GAME,
    START_GAME_FLOW,
    START_REMOTE_GAME_FLOW,
    SHOW_GAME,
    STOP_GAME,
    setLaunchError,
    stopGame,
    setLaunchData,
} from "./game.flow.actions";
import type {GameType, ErrorResponse} from "./game.flow.types";

export const TIMEOUT = "timeout";
const MAX_RECENT_GAMES = 13;

export function* bumpRecentGames(gameId: string): SagaIterator {
    let {recentGamesQueue} = (yield call(Storage.getObject, RECENT_GAMES)) || {
        recentGamesQueue: [],
    };

    recentGamesQueue = [
        gameId,
        ...(recentGamesQueue || []).filter((id: string) => id !== gameId),
    ].slice(0, MAX_RECENT_GAMES);

    yield call(Storage.setObject, RECENT_GAMES, {recentGamesQueue});
}

export const CRASH_RECOVERY_KEY = "crashRecovery";

export function* suspendWhileBlurred(
    suspend: () => void,
    restore: () => void,
): SagaIterator {
    const focusChan = eventChannel<string>((em) => {
        // Window blurs when focusing iframe, meaning that addEventListener listening
        // to "blur"/"focus" breaks when a game is focused. document.hasFocus() works
        // regardless.
        const interval = root.setInterval(
            () => (root?.document?.hasFocus() ? em("focus") : em("blur")),
            1000,
        );

        return () => root.clearInterval(interval as ReturnType<typeof setInterval>);
    });

    try {
        while (true) {
            while ((yield take(focusChan)) !== "blur");
            suspend();
            while ((yield take(focusChan)) !== "focus");
            restore();
        }
    } finally {
        focusChan.close();
    }
}

export function* saveCrashRecoverData({
    payload: {game, gameType},
}: StartGameFlowAction): SagaIterator {
    const timeStamp = Date.now();

    const saveData = () =>
        Storage.setObject(CRASH_RECOVERY_KEY, {
            gameId: game.id,
            vendorId: game.vendorId,
            gameType,
            timeStamp,
        });

    const clearData = () => Storage.removeItem(CRASH_RECOVERY_KEY);

    const handleUnload = () => {
        if (root && typeof root.removeEventListener === "function") {
            root.removeEventListener("beforeunload", handleUnload);
        }
        clearData();
    };

    try {
        yield take(SHOW_GAME);
        saveData();

        if (root && typeof root.addEventListener === "function") {
            root.addEventListener("beforeunload", handleUnload);
        }
        yield race([take(STOP_GAME), call(suspendWhileBlurred, clearData, saveData)]);
    } finally {
        // Gets called if task is cancelled, finished or thrown
        handleUnload();
    }
}

export function* trackGameCrash(): SagaIterator {
    const recoveredData = Storage.getObject(CRASH_RECOVERY_KEY) as
        | {
              gameId: string;
              vendorId: string;
              gameType: string;
              timeStamp: number;
          }
        | undefined;

    Storage.removeItem(CRASH_RECOVERY_KEY);

    if (recoveredData)
        log.warn("[Casino]: Recovered casino related game crash data", {
            gameId: recoveredData.gameId,
            vendorId: recoveredData.vendorId,
            gameType: recoveredData.gameType,
            timeStamp: recoveredData.timeStamp,
        });

    yield takeLatest(START_GAME_FLOW, saveCrashRecoverData);
}

class TimeoutError extends Error {}

export function* doFetchSaga(url: string): SagaIterator {
    return yield call(fetchAuthorized, url, {method: "GET"}, {memberFlowEnabled: true});
}

function* fetchLaunchDataSagaV2(game: Game, gameType: GameType): SagaIterator {
    let startTime = Date.now();
    const playForFun = gameType === GAME_TYPE.PLAY_FOR_FUN;

    const isPlayForFunThroughBackendVendor = [VENDOR.EVOLUTION, VENDOR.NETENT].includes(
        game.vendorId,
    );

    if (playForFun && !isPlayForFunThroughBackendVendor) {
        const playForFunUrl = getLaunchUrlV2({
            game,
            endpoint: isPlayForFunThroughBackendVendor ? "private" : "public",
            playForFun,
        });

        const {
            data: {gameUrl},
        } = yield call(pureFetch, playForFunUrl);

        return {fetchTime: 0, data: gameUrl};
    }

    const playForRealUrl = getLaunchUrlV2({
        game,
        endpoint: "private",
        playForFun,
    });

    const fetchTask = yield fork(doFetchSaga, playForRealUrl);

    const results = yield race({
        authRequired: take(AuthActions.START_AUTHENTICATION_FLOW),
        response: join(fetchTask),
        didTimeout: delay(10000),
    });

    let {response} = results;

    if (results.authRequired) {
        const {payload} = yield take(MODAL_CLOSE) ?? {};

        // Wait until the user has closed the user gambling summary modal
        // before showing the game.
        if (payload === "userGamblingSummaryModal") {
            startTime = Date.now();

            ({response} = yield race({
                didTimeout: delay(10000),
                response: join(fetchTask),
            }));
        }
    }

    yield cancel(fetchTask);

    if (response?.ok && response?.data.gameUrl) {
        return {data: response.data.gameUrl, fetchTime: Date.now() - startTime};
    }

    throw new TimeoutError();
}

// we will point towards V2 when all vendors migration is complete
export function* fetchLaunchDataSaga(game: Game, gameType: GameType): SagaIterator {
    // temporary solution to separate migrated vendors to the V2 flow
    if (VendorsMigratedToV2.includes(game.aggregateId)) {
        return yield call(fetchLaunchDataSagaV2, game, gameType);
    }

    let startTime = Date.now();

    const {gameUrl, launchUrl} = yield call(getVendorConfig, {game, gameType});

    const isPlayForFunThroughBackendVendor = [VENDOR.EVOLUTION, VENDOR.NETENT].includes(
        game.vendorId,
    );
    if (gameType === GAME_TYPE.PLAY_FOR_FUN && !isPlayForFunThroughBackendVendor) {
        return {fetchTime: 0, data: gameUrl};
    }

    const fetchTask = yield fork(doFetchSaga, launchUrl ?? "");

    const results = yield race({
        authRequired: take(AuthActions.START_AUTHENTICATION_FLOW),
        response: join(fetchTask),
        didTimeout: delay(10000),
    });

    let {response} = results;

    if (results.authRequired) {
        const {payload} = yield take(MODAL_CLOSE) ?? {};

        // Wait until the user has closed the user gambling summary modal
        // before showing the game.
        if (payload === "userGamblingSummaryModal") {
            startTime = Date.now();

            ({response} = yield race({
                didTimeout: delay(10000),
                response: join(fetchTask),
            }));
        }
    }

    yield cancel(fetchTask);

    const {gameUrl: data} = yield call(getVendorConfig, {
        game,
        gameType,
        launchData: response?.data?.launchData,
    });

    if (response?.ok && data) return {data, fetchTime: Date.now() - startTime};

    throw new TimeoutError();
}

export function* startRemoteGameFlowSaga({
    payload: {game, src},
}: StartRemoteGameFlowAction): SagaIterator {
    if (!game || game.vendorId !== VENDOR.PRAGMATICPLAY) return;

    try {
        const {data} = yield call(fetchLaunchDataSaga, game, GAME_TYPE.PLAY_FOR_REAL);

        if (src) {
            src.postMessage(
                JSON.stringify({type: "OPEN_SLOT", link: `${data}&minimode=1`}),
                "*",
            );
        } else {
            // We wont have a `src` when called from the atgapp.
            // We will just dispatch that we should open a remote game instead.
            yield put({type: OPEN_REMOTE_GAME, payload: `${data}&minimode=1`});
        }
    } catch (e: unknown) {
        /* handle error */
    }
}

export function* handleErrorResponse(error: ErrorResponse): SagaIterator {
    switch (error) {
        case ERROR_RESPONSES.RGS_DEPOSIT_LIMIT_NOT_SET:
            yield put(frameAction(ModalActions.showDepositLimitModal()));
            // App deposit limit reminder
            yield put(ModalActions.showDepositLimitReminder());
            break;
        case ERROR_RESPONSES.RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED:
            yield call(showKycModalBeforeBet);
            break;
        case ERROR_RESPONSES.RGS_CASINO_LOSS_LIMIT_NOT_SET:
        case ERROR_RESPONSES.RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET:
        case ERROR_RESPONSES.RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET:
            yield put(LimitsActions.fetchRgsLimits({vertical: VerticalEnum.CASINO}));
            while (
                (yield take(LimitsActions.RECEIVE_GET_RGS_LIMITS)).context?.vertical !==
                VerticalEnum.CASINO
            );

            yield put(ModalActions.showCasinoLossLimitModal());
            break;

        default:
            yield put(ModalActions.showCasinoErrorModal());
            break;
    }
}

export function* launchGameSaga(game: Game, gameType: GameType): SagaIterator {
    try {
        const {data, fetchTime} = yield call(fetchLaunchDataSaga, game, gameType);
        yield put(setLaunchData(data));

        const loadTime = Date.now();

        if ((yield race({timeout: delay(10000), loaded: take(SHOW_GAME)})).timeout)
            throw new TimeoutError();

        CasinoAnalytics.gameStart(game, gameType, Date.now() - loadTime + fetchTime);
        return {success: true};
    } catch (e: unknown) {
        if (e instanceof TimeoutError) return {error: TIMEOUT};
        if (e instanceof AuthenticationCancelledError) return {success: false};
        if (e instanceof AtgRequestError) {
            // v2 flow introduced a new error format; determine the correct field name based on vendor migration.
            const responseFieldName = VendorsMigratedToV2.includes(game.aggregateId)
                ? "reason"
                : "status";

            const error = e.response.data[responseFieldName] as string;

            if (Object.values(ERROR_RESPONSES).includes(error as ERROR_RESPONSES)) {
                yield put(setLaunchError(error as ERROR_RESPONSES));
                yield call(handleErrorResponse, error as ERROR_RESPONSES);
            }

            return {error};
        }

        return {error: (e as {message: string}).message};
    }
}

export function* casinoGameSaga(): SagaIterator {
    yield takeLatest(START_REMOTE_GAME_FLOW, startRemoteGameFlowSaga);
    yield spawn(trackGameCrash);
    while (true) {
        const action = yield take(START_GAME_FLOW);
        const {game, gameType, launchedBy} = action.payload;

        if (launchedBy) CasinoAnalytics.gameLaunch(game, gameType, launchedBy);

        const [res] = yield race([call(launchGameSaga, game, gameType), take(STOP_GAME)]);

        if (res?.success) {
            if (gameType === GAME_TYPE.PLAY_FOR_REAL)
                yield call(bumpRecentGames, game.id);

            yield take(STOP_GAME);
            CasinoAnalytics.gameClose(game, gameType);
        } else if (res) {
            yield put(stopGame());
            if (res.error) CasinoAnalytics.gameStartError(game, gameType, res.error);
        }
    }
}
