import type {Reducer} from "redux";
import {combineReducers} from "redux";
import {feedReducer} from "@atg-casino-shared/data-access-feed";
import {favoritesReducer} from "@atg-casino-shared/data-access-favorites";
import {collectionReducer} from "@atg-casino-shared/data-access-collection";
import {pageReducer} from "@atg-casino-shared/data-access-page";
import {gameReducer} from "@atg-casino-shared/data-access-game";
import {tagReducer} from "@atg-casino-shared/data-access-tag";
import {atgJackpotReducer} from "@atg-casino-shared/data-access-atg-jackpot";
// WAIT FOR: figure out a way to break this 1 to 1 circular
// eslint-disable-next-line @nx/enforce-module-boundaries
import {gameFlowReducer} from "@atg-casino-shared/data-access-game-flow";

export const casinoReducer: Reducer<any, any> = combineReducers({
    pages: pageReducer,
    games: gameReducer,
    tags: tagReducer,
    collections: collectionReducer,
    game: gameFlowReducer,
    feeds: feedReducer,
    favorites: favoritesReducer,
    atgJackpot: atgJackpotReducer,
});
