import type {Reducer, Action} from "redux";
import {LOADING_STATUS} from "@atg-shared/fetch-types";
import {fetchSelectors} from "@atg-shared/fetch-redux";
import type {FetchState, LoadingStatus} from "@atg-shared/fetch-types";
import {isDevice} from "@atg/utils/device";
import type {FETCH} from "@atg-shared/fetch-redux";
import type {GameMin} from "@atg-casino-shared/types-game";
import type {LiveTableType} from "@atg-casino-shared/types-feed";
import type {
    CollectionQuery,
    GamesCollectionByTagQuery,
} from "@atg-casino-shared/data-access-contentful-cms";
import _ from "lodash";

export type WithoutFetch<T> = Exclude<T, {type: typeof FETCH}>;
type ActionWithContext<Key extends string> = Action & {
    context?: {[K in Key]: string};
};
type State<Entity> = Record<string, Entity>;

export function createEntityFetchReducer<
    Entity,
    Key extends string,
    A extends ActionWithContext<Key>,
>(
    requestAction: string,
    receiveAction: string,
    resetAction: string | null | undefined,
    reducer: Reducer<Entity, A>,
    contextKey: Key,
): Reducer<State<Entity>, A> {
    const getKey = (action: A) => action.context && action.context[contextKey];

    const reduceEntity = (state: State<Entity>, action: A, key?: string) =>
        key && {...state, [key]: reducer(state[key], action)};

    const reduceAll = (state: State<Entity>, action: A) =>
        Object.keys(state).reduce((s, k) => ({...s, [k]: reducer(state[k], action)}), {});

    return (state = {}, action) => {
        switch (action.type) {
            case requestAction:
            case receiveAction:
                return reduceEntity(state, action, getKey(action)) || state;
            case resetAction:
                return resetAction ? reduceAll(state, action) : state;
            default:
                return state;
        }
    };
}

export const getLoadingStatus = <S extends FetchState<unknown>>(
    state: S | null | undefined,
): LoadingStatus =>
    state ? fetchSelectors.getLoadingStatus(state) : {status: LOADING_STATUS.INITIAL};

export const filterUA = ({hideOnDesktop, hideOnDevice}: GameMin) =>
    isDevice() ? !hideOnDevice : !hideOnDesktop;

export const sortAtoZ = (a: string, b: string) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

export const filteredGameIds = (games: GameMin[]): string[] =>
    games.filter(filterUA).map(({id}) => id);

export const getTablePlayersInfo = (
    liveTableInfo: LiveTableType,
    options?: {shortenSuffix?: boolean},
) => {
    if (!liveTableInfo.open) {
        return "Stängt";
    }
    if (liveTableInfo?.availableSeats !== null) {
        const availableSeatsSuffix = options?.shortenSuffix
            ? "platser"
            : "lediga platser";
        return `${liveTableInfo.availableSeats} ${availableSeatsSuffix}`;
    }
    if (liveTableInfo?.tableIsFull) {
        return "Fullsatt";
    }
    if (liveTableInfo?.playerCount !== null) {
        return `${liveTableInfo.playerCount} spelare`;
    }

    return null;
};

export const iFrameSize = (size: {height: number; width: number} | null | undefined) =>
    size
        ? {
              minHeight: `${size.height}px`, // Required for iOS
              height: `${size.height}px`,
              width: `${size.width}px`,
          }
        : {};

function reduceTags(
    tags: CollectionQuery["collection"]["items"][0]["tagsCollection"]["items"],
) {
    let andIndex = 0;
    return tags.reduce(
        (
            acc: Array<string[] | string>,
            {id, booleanOperator, __typename}: Record<string, string>,
        ) => {
            if (__typename === "TagRelation") {
                if (booleanOperator === "(AND") {
                    acc.push([]);
                }
                if (booleanOperator === ")") {
                    andIndex += 1;
                }
                return acc;
            }
            if (Array.isArray(acc[andIndex])) {
                (acc as Array<string[]>)[andIndex].push(id);
            } else {
                acc.push(id);
                andIndex += 1;
            }
            return acc;
        },
        [],
    );
}

export function filterByAndOperator(
    games: GamesCollectionByTagQuery["gameCollection"]["items"],
    tags: CollectionQuery["collection"]["items"][0]["tagsCollection"]["items"],
    highlightedGames: CollectionQuery["collection"]["items"][0]["highlightedGamesCollection"]["items"],
) {
    const isTagMatch = (
        tag: string,
        game: GamesCollectionByTagQuery["gameCollection"]["items"][0],
    ) => !!game.tagsCollection.items.filter(({id}: {id: string}) => id === tag).length;
    const reducedTags = reduceTags(tags);
    const gamesToSort =
        typeof reducedTags[0] === "string"
            ? games
            : (reducedTags as Array<string[]>)
                  .reduce(
                      (
                          acc: GamesCollectionByTagQuery["gameCollection"]["items"],
                          tagSet: string[],
                      ) => {
                          const newGames = [...games].filter((game) =>
                              tagSet.every((tag) => isTagMatch(tag, game)),
                          );
                          return [...acc, ...newGames];
                      },
                      [],
                  )
                  .sort((a, b) => sortAtoZ(a.id, b.id));

    const highlightedSorted = [...highlightedGames].sort((a, b) => sortAtoZ(a.id, b.id));
    return [...highlightedSorted, ...gamesToSort];
}

export function flattenTags(
    tags:
        | CollectionQuery["collection"]["items"][0]["tagsCollection"]["items"]
        | undefined,
) {
    return tags
        ? tags
              .filter((tag) => tag.__typename === "Tag")
              .map((item) => (item.__typename === "Tag" ? item.id : ""))
        : [];
}
