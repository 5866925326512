import type {Action} from "redux";
import {runLazySaga} from "@atg-shared/lazy-store";
import {fetchSaga} from "@atg-shared/fetch-redux";
import {feedsListenerSaga} from "@atg-casino-shared/data-access-feed";
import {casinoSaga} from "@atg-casino-shared/data-access-root-saga";
import type {LazyStore} from "@atg-shared/lazy-store";
import {atgJackpotSaga} from "@atg-casino-shared/data-access-atg-jackpot";

export default function rootSaga(store: LazyStore<unknown, Action>) {
    runLazySaga(store, fetchSaga);
    runLazySaga(store, casinoSaga);
    runLazySaga(store, feedsListenerSaga);
    runLazySaga(store, atgJackpotSaga);
}
