import {createSelector} from "reselect";
import type {GlobalState} from "@atg-casino-shared/types-root";
import type {AtgJackpot, PlayerJackpotWin} from "@atg-casino-shared/types-atg-jackpot";

export const selectAtgJackpotPlayerId = (state: GlobalState): number | undefined =>
    state.casino.atgJackpot.jackpotPlayerId;

export const selectOptedIn = (state: GlobalState): boolean =>
    state.casino.atgJackpot.optedIn;

export const selectAtgJackpots = createSelector(
    (state: GlobalState) => state.casino.atgJackpot.jackpots,
    (jackpots) => Object.values(jackpots).sort((a, b) => b.balance - a.balance),
);

export const selectCurrentUserJackpot = (
    state: GlobalState,
): PlayerJackpotWin | undefined => state.casino.atgJackpot.currentUserJackpot;

export const selectOtherUserJackpot = (
    state: GlobalState,
): PlayerJackpotWin | undefined => state.casino.atgJackpot.otherUserJackpot;

export const selectLogInLoading = (state: GlobalState): boolean =>
    state.casino.atgJackpot.loginLoading;

export const selectOptInLoading = (state: GlobalState): boolean =>
    state.casino.atgJackpot.optInLoading;

export const selectLogInActionError = (state: GlobalState): boolean | undefined =>
    state.casino.atgJackpot.loginActionError;

export const selectOptInActionError = (state: GlobalState): boolean | undefined =>
    state.casino.atgJackpot.optInActionError;

export const selectJackpotById =
    (jackpotId: number) =>
    (state: GlobalState): AtgJackpot | undefined =>
        state.casino.atgJackpot.jackpots?.[jackpotId];
