/* eslint-disable @nx/enforce-module-boundaries */
import type {Aggregator, Vendor} from "@atg-casino-shared/types-vendor";
import type {FetchState} from "@atg-shared/fetch-types";
import type {WinFeedType} from "@atg-casino-shared/types-feed";
import type {TagMin} from "@atg-casino-shared/types-tag";

export type RecentGames = {recentGamesQueue: string[]};

export enum GAME_TYPE {
    PLAY_FOR_FUN = "PlayForFun",
    PLAY_FOR_REAL = "PlayForReal",
}

export enum GAME_CATEGORY {
    SLOT = "SLOT",
    LIVE_CASINO = "LIVE_CASINO",
    TABLE_GAME = "TABLE_GAME",
    BINGO_GAME = "BINGO_GAME",
    VIRTUAL_GAME = "VIRTUAL_GAME",
}

export type GameType = GAME_TYPE;

export type GameCategory = GAME_CATEGORY;

export type Background = {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    default: string;
};

export type Logo = {
    sm: string;
    md: string;
    lg: string;
    default: string;
};

export type Banner = {
    text: string;
    textColor: string;
    bgColor: string;
};

export type GameMin = {
    id: string;
    title: string;
    background?: Background | null;
    logo?: Logo | null;
    hideOnDesktop?: boolean | null;
    hideOnDevice?: boolean | null;
    banner?: Banner | null;
    maintenance?: string | null;
    shortDescription?: string | null;
    winFeed?: WinFeedType | null;
    startParams?: string | null;
    vendorGameId: string;
    walletId?: string | null;
    vendorId: Vendor;
    aggregateId: Aggregator;
    type?: GameCategory;
    jackpotId?: string;
    tableId?: string;
    prominentColors?: ProminentColors;
    colorOverride?: string;
};

export type GameSEO = {
    seoContentTop: {json: any; links: any};
    seoContentBottom: {json: any; links: any};
    randomGame: {
        title: string;
        description: string;
        collection: {
            highlightedGamesCollection: {
                items: Array<{id: string}>;
            };
        };
    };
    singleGame: {
        color: string;
        game: {
            id: string;
        };
    };
};

export type Game = GameMin & {
    description: string;
    rtp: number | null;
    vendor: string;
    previewVideo?: string | null;
    startUrl?: string;
    previewImage?: string | null;
    relatedGames?: string[];
    relatedGamesTheme?: string[];
    relatedGamesFeature?: string[];
    productionYear: number;
    tags: TagMin[];
    metaTitle?: string | null;
    metaDescription?: string | null;
    fullGame: true;
    atgJackpot: boolean;
};
export type GameCard = {
    id: string;
    title: string;
    background?: Pick<Background, "default"> | null;
    logo?: Pick<Logo, "default"> | null;
    banner?: Banner | null;
    maintenance?: string | null;
    startParams?: string | null;
    vendorId: Vendor;
    jackpotId?: string;
    tableId?: string;
};

export type ProminentColors = {
    darkPrimary: string;
    darkSecondary: string;
    lightPrimary: string;
    lightSecondary: string;
};

export type GameResponse = Omit<Game, "fullGame"> & {
    relatedGames: GameMin[];
    relatedGamesTheme?: GameMin[];
    relatedGamesFeature?: GameMin[];
};

export type RelatedGames = {
    all: string[];
    feature: string[];
    theme: string[];
};

export type GameFetchState = FetchState<{data?: Game | GameMin}>;

export type GamesData = {
    [key: string]: GameFetchState;
};

export type GamesState = FetchState<{
    data: GamesData;
}>;
