/**
 * Wraps a value to indicate that it should not be translated. This function is essentially a no-op, but it
 * is useful for explicitly marking values that should not be translated without adding eslint-disable comments.
 * @param x The value to wrap.
 * @returns The same value that was passed in.
 */
export function noTrans<T>(x: T) {
    return x;
}

/**
 * Wraps a JSX element to indicate that it should not be translated. This component adds no functionality in runtime, but it is useful for explicitly marking values that should not be translated without adding eslint-disable comments.
 * @param {children: React.ReactNode} {children: x}
 * @returns JSX.Element
 */
export function NoTrans({children: x}: {children: React.ReactNode}) {
    return x as JSX.Element;
}
