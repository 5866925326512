import {
    call,
    put,
    take,
    fork,
    cancelled,
    select,
    takeEvery,
    delay,
} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import type {AtgJackpot} from "@atg-casino-shared/types-atg-jackpot";
import {
    atgCasinoJackpotEvent,
    atgCasinoJackpotOptinEvent,
} from "@atg-casino-shared/utils-analytics";
import {isApp} from "@atg-shared/system";
import {
    clearWinEvent,
    fetchAtgJackpots,
    handleWinEvent,
    removeAtgJackpot,
    updateAtgJackpot,
} from "../actions";
import * as constants from "../constants";
import type {
    HandleWinEventAction,
    JackpotEvent,
    LogInResponse,
    OptInResponse,
} from "../types";
import {establishChannel, convertJackpotEvent} from "../helpers";
import {selectAtgJackpotPlayerId, selectJackpotById} from "../selectors";

export function* handleEvent(event: JackpotEvent) {
    if (!event.success) return;

    switch (event.data.messageType) {
        case "jpeJackpotChangeStatus":
            if (event.data.isJackpotClosed) {
                yield put(removeAtgJackpot({jackpotId: event.data.jackpotId.toString()}));
            }

            break;
        case "jpeJackpotWinNotification": {
            const jackpotPlayerId: number | undefined = yield select(
                selectAtgJackpotPlayerId,
            );

            const jackpot: AtgJackpot | undefined = yield select(
                selectJackpotById(event.data.jackpotId),
            );

            yield put(
                handleWinEvent({
                    event: event.data,
                    jackpotName: jackpot?.name,
                    jackpotPlayerId,
                }),
            );
            break;
        }
        case "updateJpeJackpotBalance":
            yield put(updateAtgJackpot(convertJackpotEvent(event.data)));
            break;
        default:
            break;
    }
}

export function* establishChannels(): SagaIterator {
    const channel = yield call(establishChannel, constants.jpeUrl);

    try {
        while (true) {
            const event = yield take(channel);

            if (event) yield* handleEvent(event);
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

export function* clearWinJackpotEvent(action: HandleWinEventAction): SagaIterator {
    const {jackpotName} = action.payload;

    yield delay(
        jackpotName === "Mega"
            ? constants.WIN_EVENT_CLEAR_TIMEOUT_LONG // display win event of mega jackpot longer
            : constants.WIN_EVENT_CLEAR_TIMEOUT,
    );
    yield put(clearWinEvent({currentUser: false}));
}

function handleReceiveLogin({payload}: {type: string; payload: LogInResponse}) {
    const {optedIn} = payload;

    atgCasinoJackpotEvent(optedIn, isApp);
}

function handleReceiveOptIn({payload}: {type: string; payload: OptInResponse}) {
    const {optedIn} = payload;

    atgCasinoJackpotOptinEvent(optedIn, isApp);
}

export function* atgJackpotSaga() {
    yield put(fetchAtgJackpots());
    yield fork(establishChannels);
    yield takeEvery(constants.RECEIVE_ATG_JACKPOT_LOG_IN, handleReceiveLogin);
    yield takeEvery(constants.RECEIVE_OPT_IN, handleReceiveOptIn);
    yield takeEvery(constants.WIN_EVENT_OTHER_USER, clearWinJackpotEvent);
}
