import {eventChannel, type EventChannel} from "redux-saga";
import EventSourceApp from "react-native-sse";
import type {AtgJackpot} from "@atg-casino-shared/types-atg-jackpot";
import {formatCurrency} from "@atg-shared/currency";
import {GAME_CATEGORY, GAME_TYPE} from "@atg-casino-shared/types-game";
import {isApp} from "@atg-shared/system";
import type {JackpotBalanceEvent, JackpotEvent} from "../types";

const roundJackpotBalance = (balance: number): number => Math.round(balance);

export const convertJackpotEvent = (jackpot: JackpotBalanceEvent): AtgJackpot => ({
    id: jackpot.jackpotId.toString(),
    name: jackpot.jackpotAlias,
    balance: roundJackpotBalance(jackpot.data?.SEK.balance ?? 0),
    currency: "SEK",
});

export const establishChannel = (endpoint: string): EventChannel<JackpotEvent> =>
    eventChannel((emit) => {
        let eventSource: EventSource | EventSourceApp;

        try {
            if (isApp) {
                eventSource = new EventSourceApp(endpoint);
                eventSource.addEventListener(
                    "message",
                    (event) => event.data && emit(JSON.parse(event?.data ?? "")),
                );
            } else {
                eventSource = new EventSource(endpoint);
                eventSource.onmessage = (event) => emit(JSON.parse(event.data));
            }
        } catch (e: unknown) {
            console.error("Error while estavlishing server connection", e);
        }

        return () => eventSource.close();
    });

export const jackpotsArrayToObject = (
    jackpots: AtgJackpot[],
): Record<string, AtgJackpot> =>
    jackpots.reduce((acc, jackpot) => {
        acc[jackpot.id] = {...jackpot, balance: roundJackpotBalance(jackpot.balance)};
        return acc;
    }, {} as Record<string, AtgJackpot>);

export const shouldRenderAtgJackpotWidget = (
    gameType: GAME_TYPE,
    category: GAME_CATEGORY,
): boolean => gameType === GAME_TYPE.PLAY_FOR_REAL && category === GAME_CATEGORY.SLOT;

export const formatAtgJackpotAmount = (amount: number) => formatCurrency(amount * 100);
